import React from 'react';
import {
  Container,
  Footer,
  LinkSection,
  SocialSection,
  AppSection,
  Title,
  Link,
  Tagline,
  AppBadge,
  SocialBadge,
} from './SiteFooter.style';
import Devo from 'image/Devo-bird.png';
import Apple from 'image/apple-app-store.svg';
import Google from 'image/google-play-store.svg';
import { iosAppStoreURL, androidAppStoreURL } from 'helper/mobileapp';
import { logAnalytics } from 'helper/gtm/helper';
import TrustBox from 'components/TrustBox/TrustBox';
import { CATEGORY_ALCOHOL_ROUTE, CATEGORY_CIGARETTE_ROUTE, CATEGORY_ICE_CREAM_ROUTE, CATEGORY_MILK_ROUTE, CATEGORY_SNACK_ROUTE } from 'constants/navigation';

type CategoryFooterLink = {
  id: string;
  name: string;
  url: string;
};
const CATEGORIES: CategoryFooterLink[] = [
  { id: "ice-cream-delivery",             name: "Ice Cream",  url: CATEGORY_ICE_CREAM_ROUTE },
  { id: "snack-delivery",                 name: "Snacks",     url: CATEGORY_SNACK_ROUTE },
  { id: "alcohol-delivery",               name: "Alcohol",    url: CATEGORY_ALCOHOL_ROUTE },
  { id: "cigarette-and-tobacco-delivery", name: "Cigarettes", url: CATEGORY_CIGARETTE_ROUTE },
  { id: "milk-delivery",                  name: "Milk",       url: CATEGORY_MILK_ROUTE },
];

type SiteFooterProps = {
  hideCategories?: boolean;
};

const HelpSection = ({ contactClicked }) => (
  <>
    <Title>Help</Title>
    <Link>
      <a onClick={contactClicked}>Contact</a>
    </Link>
    <Link>
      <a href="/help" onClick={() => logAnalytics('account_help_faq')}>FAQs</a>
    </Link>
  </>
);

const LegalSection = () => (
  <>
    <Title>Legal</Title>
    <Link>
      <a href="/legal/terms" onClick={() => logAnalytics('account_nav_terms_conditions')}>Terms &amp; Conditions</a>
    </Link>
    <Link>
      <a href="/legal/privacy" onClick={() => logAnalytics('account_nav_privacy_policy')}>Privacy Policy</a>
    </Link>
    <Link>
      <a href="/legal/cookies" onClick={() => logAnalytics('account_nav_cookies')}>Cookies</a>
    </Link>
  </>
);

const CategoriesSection = ({ categoryClicked }) => (
  <>
    <Title>Categories</Title>
    {CATEGORIES.map((category, index) => (
      <Link>
        <a key={index} href={category.url} onClick={() => categoryClicked(category.id)}>
          {category.name} Delivery
        </a>
      </Link>
    ))}
  </>
);

const SiteFooter: React.FC<SiteFooterProps> = ({
  hideCategories = false,
}) => {

  const contactClicked = (e) => {
    e.preventDefault();
    logAnalytics('open_chat');
    // @ts-ignore
    window.Intercom('show');
  }

  const categoryClicked = (id: string) => {
    logAnalytics("footer_category_landing_clicked", { id });
  };

  return (
    <Container>
      <Footer>
        <LinkSection>
          <LegalSection />
          
          {!hideCategories && <HelpSection contactClicked={contactClicked} />}
        </LinkSection>
        <LinkSection>
          {hideCategories ? (
            <HelpSection contactClicked={contactClicked} />
          ) : (
            <CategoriesSection categoryClicked={categoryClicked} />
          )}
        </LinkSection>
        <SocialSection>
          <Title>Follow us</Title>
          <SocialBadge href="https://www.instagram.com/devodelivery/">
            <div className='instagram' />
          </SocialBadge>
          <SocialBadge href="https://twitter.com/DevoDelivery">
            <div className='twitter' />
          </SocialBadge>
          <SocialBadge href="https://www.facebook.com/DevoDelivery/">
            <div className='facebook' />
          </SocialBadge>
        </SocialSection>
        <AppSection>
          <Title>Get the app</Title>
          <AppBadge href={`${iosAppStoreURL}?utm_medium=Website&utm_source=Footer`}>
            <img src={Apple} alt='Apple App Store' />
          </AppBadge>
          <AppBadge href={`${androidAppStoreURL}&utm_medium=Website&utm_source=Footer`}>
            <img src={Google} alt='Google Play Store' />
          </AppBadge>
        </AppSection>
        {/* <TrustBox /> */}
        <Tagline>
          <img src={Devo} alt="Devo" />
          © {new Date().getFullYear()} Devo Technologies Ltd
        </Tagline>
      </Footer>
    </Container>
  );
};

export default SiteFooter;
