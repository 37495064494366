import React from 'react';
import Head from 'next/head';
import Devo from '../image/Devo-bird.png';

type SeoProps = {
  title: string;
  description: string;
  canonical?: string;
  css?: string;
  js?: string;
  image?: string;
  schema?: any[];
  noindex?: boolean;
  noindexnofollow?: boolean;
};

export const SEO: React.FC<SeoProps> = ({
  title,
  description,
  canonical,
  css,
  js,
  image,
  schema = [],
  noindex = false,
  noindexnofollow = false,
}) => {
  var _title = title;
  if (process.env.NEXT_PUBLIC_DEVO_ENV !== 'production') {
    _title = `[${process.env.DEVO_ENV.toUpperCase()}] ${_title}`;
    if (!process.env.NEXT_PUBLIC_DEVO_ENV)  _title = `[LOCAL] ${_title}`;
  }

  const hostname = typeof window !== 'undefined' ? window.location.hostname : 'https://devo.co.uk';
  const url = `${hostname}${canonical}`;
  return (
    <Head>
      <title>{_title}</title>
      <meta name="description" content={description} />
      <meta property="og:type" content="website" />
      <meta name="og:title" property="og:title" content={title} />
      <meta
        name="og:description"
        property="og:description"
        content={description}
      />
      <meta property="og:site_name" content="Devo" />
      <meta property="og:url" content={url} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={_title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:site" content="@DevoDelivery" />
      <meta name="twitter:creator" content="@DevoDelivery" />
      <meta name="apple-itunes-app" content="app-id=1317731607" />
      {css && <link rel="stylesheet" href={`${css}`} />}
      {image ? (
        <meta property="og:image" content={`${image}`} />
      ) : (
        <meta
          property="og:image"
          content={Devo}
        />
      )}
      {image && <meta name="twitter:image" content={`${image}`} />}
      {canonical && <link rel="canonical" href={url} />}
      {js && <script type="text/javascript" src={`${js}`}></script>}
      {schema.map((data, index) => 
        <script key={index} type="application/ld+json" dangerouslySetInnerHTML={{__html: JSON.stringify(data)}} />
      )}
      {noindex && <meta name="robots" content="noindex" />}
      {noindexnofollow && <meta name="robots" content="noindex,nofollow" />}
    </Head>
  );
}
