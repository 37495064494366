
export const iosAppStoreURL = 'https://apps.apple.com/app/id1317731607';
export const androidAppStoreURL = 'https://play.google.com/store/apps/details?id=co.uk.devo.clientapp';

export type MobileOperatingSystem = 'windows' | 'android' | 'ios' | 'unknown';
export const getMobileOperatingSystem = (): MobileOperatingSystem => {
    // @ts-ignore
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/windows phone/i.test(userAgent)) return 'windows';
    if (/android/i.test(userAgent)) return 'android';
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) return 'ios';
    return 'unknown';
};