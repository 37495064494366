import styled from 'styled-components';
import Facebook from 'image/Facebook.svg';
import Twitter from 'image/Twitter.svg';
import Instagram from 'image/Instagram.svg';

export const Container = styled.div`
  background-color: #fff;
  border-top: solid 2px #f4f4f4;
`;

export const Footer = styled.div`
  width: 80%;
  margin: 0 auto;
  padding: 50px 0;

  @media (max-width: 767px) {
    width: 90%;
    padding-top: 20px;
  }
`;

export const LinkSection = styled.div`
  display: inline-block;
  width: 25%;
  vertical-align: top;

  @media (max-width: 1000px) {
    width: 50%;
    margin-bottom: 50px;
  }

  @media (max-width: 767px) {
    width: 100%;
    margin-bottom: 40px;
    text-align: center;
  }
`;

export const SocialSection = styled.div`
  display: inline-block;
  width: 25%;
  vertical-align: top;

  @media (max-width: 1000px) {
    width: 50%;
    margin-bottom: 50px;
  }

  @media (max-width: 767px) {
    width: 100%;
    margin-bottom: 50px;
    text-align: center;

    a:last-child {
      div {
        margin-right: 0;
      }
    }
  }
`;

export const AppSection = styled.div`
  display: inline-block;
  width: 25%;
  vertical-align: top;

  @media (max-width: 1000px) {
    width: 50%;
    margin-bottom: 50px;
  }

  @media (max-width: 767px) {
    width: 100%;
    margin-bottom: 0;
    text-align: center;

    a:last-child {
      img {
        margin-right: 0;
      }
    }
  }
`;

export const Title = styled.h3`
  font-size: 16px;
  font-weight: 600;
  padding: 30px 0 10px 0;

  &:first-child {
    padding-top: 0;
  }
`;

export const Link = styled.div`
  display: block;
  padding: 8px 0 0 0;

  a {
    color: rgb(118, 118, 118);
      font-weight: 400;
    font-size: 16px;
    cursor: pointer;
  }

  a:hover {
    color: var(--devo-red);
  }
`;

export const SocialBadge = styled.a`

  div {
    display: inline-block;
    margin-right: 20px;
    transition: background-color 0.2s linear;
    background-color: black;
    height: 30px;
    width: 30px;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active),
    @supports (-ms-accelerator:true) or (-ms-ime-align:auto) {
      background-color: transparent;
      opacity: 0.85;
    }
  }

  div:hover {
    background-color: var(--devo-red);
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active),
    @supports (-ms-accelerator:true) or (-ms-ime-align:auto) {
      background-color: transparent;
      opacity: 1;
    }
  }

  .facebook {
    -webkit-mask: url(${Facebook}) no-repeat center;
    mask: url(${Facebook}) no-repeat center;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active),
    @supports (-ms-accelerator:true) or (-ms-ime-align:auto) {
      background-image: url(${Facebook});
    }
  }

  .twitter {
    -webkit-mask: url(${Twitter}) no-repeat center;
    mask: url(${Twitter}) no-repeat center;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active),
    @supports (-ms-accelerator:true) or (-ms-ime-align:auto) {
      background-image: url(${Twitter});
    }
  }

  .instagram {
    -webkit-mask: url(${Instagram}) no-repeat center;
    mask: url(${Instagram}) no-repeat center;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active),
    @supports (-ms-accelerator:true) or (-ms-ime-align:auto) {
      background-image: url(${Instagram});
    }
  }
`;

export const AppBadge = styled.a`
  img {
    margin: 3px 8px 3px 0;
    height: 40px;
    opacity: 0.85;
  }

  img:hover {
    opacity: 1;
  }
`;

export const Tagline = styled.span`
  color: #4b4b4b;
  margin-top: 40px;
  display: block;
  font-size: 14px;
  color: rgb(118, 118, 118);

  img {
    height: 30px;
    margin-bottom: -8px;
    margin-right: 10px;
  }

  @media (max-width: 767px) {
    text-align: center;
  }
`;
